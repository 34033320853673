<template>
	<div class="wrapper">
		<h4 style="margin: 15px 0">机构送广告设置</h4>
		<el-table v-loading="loading" :data="list" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="type" label="类型"></el-table-column>
			<el-table-column prop="title" label="标题"></el-table-column>
			<el-table-column prop="subtitle" label="副标题"></el-table-column>
			<el-table-column prop="btntxt" label="行动按钮"></el-table-column>
			<el-table-column prop="max" label="每天封顶"></el-table-column>
			<el-table-column prop="each" label="每次获得"></el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					{{ scope.row.status == 1 ? '可用' : '禁用'}}
				</template>
			</el-table-column>
			<el-table-column prop="ad_score" label="操作">
				<template slot-scope="scope">
					<el-link type="primary" @click="showDetail('edit',scope.row.id,scope.row.status,scope.row.type,scope.row.title,scope.row.each,scope.row.max,scope.row.subtitle,scope.row.btntxt)">编辑</el-link>
					&nbsp;&nbsp;
					<el-popconfirm
						confirm-button-text='是的'
						cancel-button-text='不用了'
						icon="el-icon-info"
						icon-color="red"
						title="确定要上架/下架吗?"
						@confirm='showDetail("soldOut",scope.row.id,scope.row.status)'
					>
					<el-link slot="reference" type="primary">{{scope.row.status == 1 ? '禁用' : '开启'}}</el-link>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<setting @close='close' :dialogVisible='dialogVisible' :formInline='formInline' @confirm='confirm'></setting>
	</div>
</template>
<script>
	import setting from '@/components/setting.vue'
	import {
		marketingTask,
		setTask,
		changeTaskStatus
	} from '@/api/marketing.js';
	export default {
		name: "CreditSetting",
		data() {
			return {
				loading: true,
				input: '',
				list: [],
				dialogVisible: false,
				formInline: {}
			}
		},
		created() {
			this.getMarketingTask()
		},
		components: {
			setting
		},
		methods: {
			close(){
				this.dialogVisible = false
			},
			//保存设置
			confirm(formInline) {
				setTask({
					id: this.id,
					every_time_value: formInline.each,
					title: formInline.title,
					subtitle: formInline.subtitle,
					max_value: formInline.max,
					btntxt: formInline.btntxt,
				}).then(res => {
					this.$message({
						message: res.msg,
						type: res.code == 200 ? 'success' : 'warning'
					});
					if (res.code == 200) this.getMarketingTask()
				}).catch(rej => {
					console.log(rej)
				})
			},
			// 获取配置u列表
			getMarketingTask() {
				marketingTask({
					type: 2
				}).then(res => {
					this.list = res.data
					this.loading = false
				}).catch(rej => {
					console.log((rej))
				})
			},
			showDetail(differentia, id, status, type, title, each, max, subtitle, btntxt) {
				if (differentia == 'edit') {
					this.id = id
					this.dialogVisible = true;
					this.formInline = {
						type,
						title,
						each,
						max,
						subtitle,
						btntxt
					}
				}else if(differentia == 'soldOut'){
					changeTaskStatus({
						id,
						status: status == 0 ? 1 : 0
					}).then(res => {
						if(res.code == 200) {
							this.getMarketingTask()
						}
					}).catch(rej => {
						console.log(rej)
					})
				}

			},
		}
	};
</script>
<style lang="less">
	.setting {
		padding: 20px 50px;
	}

	.width100 {
		box-sizing: border-box;
		margin: 0 10px;
		width: 100px;
	}

	.title {
		margin: 20px 0;
		padding: 0 0 10px 10px;
		font-size: 18px;
		border-bottom: 1px solid #DCDFE6;
		font-weight: 600;
	}

	.from-line {
		margin-bottom: 10px;
	}
</style>
